import styled, { css } from 'styled-components/macro'
import { media } from '../../theme/mixins/media'

interface BreakInfo {
  start?: number
  size: number
  justifySelf?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly'
}

interface Props {
  mobile?: number | BreakInfo
  tablet?: number | BreakInfo
  desktop?: number | BreakInfo
  desktopLarge?: number | BreakInfo
  desktopHuge?: number | BreakInfo

  flex?: boolean
  align?: 'start' | 'center' | 'end' | 'stretch' | 'baseline'
  justify?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly'
}

const alignToValueMapping = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  stretch: 'stretch',
  baseline: 'baseline',
}

const justifyToValueMapping = {
  start: 'flex-start',
  end: 'flex-end',
  center: 'center',
  between: 'space-between',
  around: 'space-around',
  evenly: 'space-evenly',
}

export const Col = styled.div<Props>`
  grid-column-start: ${p => (p.mobile ? buildSize(p.mobile) : 'span 12')};
  justify-self: ${p => typeof p.mobile === 'object' && p.mobile.justifySelf};

  ${p =>
    p.flex &&
    css`
      display: flex;
      align-items: ${p.align && alignToValueMapping[p.align]};
      justify-content: ${p.justify && justifyToValueMapping[p.justify]};
    `}

  ${p =>
    p.tablet &&
    media.tablet`
    grid-column: ${buildSize(p.tablet)};
    justify-self: ${typeof p.tablet === 'object' && p.tablet.justifySelf};
  `}

  ${p =>
    p.desktop &&
    media.desktop`
    grid-column: ${buildSize(p.desktop)};
    justify-self: ${typeof p.desktop === 'object' && p.desktop.justifySelf};
  `}

  ${p =>
    p.desktopLarge &&
    media.desktopLarge`
    grid-column: ${buildSize(p.desktopLarge)};
    justify-self: ${typeof p.desktopLarge === 'object' &&
      p.desktopLarge.justifySelf};
  `}

  ${p =>
    p.desktopHuge &&
    media.desktopHuge`
    grid-column: ${buildSize(p.desktopHuge)};
    justify-self: ${typeof p.desktopHuge === 'object' &&
      p.desktopHuge.justifySelf};
  `}
`

function buildSize(size: number | BreakInfo) {
  if (typeof size === 'number') {
    return `span ${size}`
  } else if (typeof size === 'object' && size.start) {
    return `${size.start} / span ${size.size}`
  } else if (typeof size === 'object' && !size.start) {
    return `span ${size.size}`
  }
  return null
}
