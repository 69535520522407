import styled from 'styled-components/macro'
import { rem } from 'polished'
import { media } from '../../theme/mixins/media'
import {
  VerticalMarginProps,
  verticalMarginsCss,
} from '../../theme/mixins/margins'
import { grid } from '../../theme'

interface Props {
  nogap?: boolean
  align?: 'center'
  justify?: 'center'
}

export const Row = styled.div<Props & VerticalMarginProps>`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: ${p => !p.nogap && rem(grid.default.gap)};
  align-items: ${p => p.align && 'center'};
  justify-items: ${p => p.justify && 'center'};

  ${verticalMarginsCss};

  ${media.desktopLarge`
    grid-gap: ${(p: Props) => !p.nogap && rem(grid.desktopLarge.gap)};
  `}
`
