import styled from 'styled-components/macro'
import mix from 'polished/lib/color/mix'
import { color } from '../theme'

export const ContactLink = styled.a`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  text-decoration: none;
  color: ${color.turquoise};
  font-weight: bold;
  transition: all 0.3s;

  path {
    fill: ${color.turquoise};
  }

  &:hover {
    color: ${mix(0.2, color.black, color.turquoise)};
    path {
      fill: ${mix(0.2, color.black, color.turquoise)};
    }
  }
`
