import React from 'react'

export const Mapa = () => (
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2927.218761868453!2d-1.6487486841418515!3d42.80486297915947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd50925070e6a579%3A0x7b7f1c5d45dd101b!2sCentro%20Feeling%20Osteopat%C3%ADa%20y%20Fisioterapia!5e0!3m2!1ses!2ses!4v1577722233281!5m2!1ses!2ses"
    width="100%"
    height="400"
    frameBorder="0"
    style={{ border: 0, marginBottom: '-6px' }}
    allowFullScreen
    loading="lazy"
  ></iframe>
)
