import * as React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

export const Seo: React.FC<Props> = ({ title }) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    {/* <link rel="canonical" href="https://www.centrofeeling.es" /> */}
  </Helmet>
)
