import styled, { css } from 'styled-components/macro'
import { font } from '../../theme/mixins/font'
import { sizes } from '../../theme/sizes'
import { color } from '../../theme/color'
import rem from 'polished/lib/helpers/rem'
import mix from 'polished/lib/color/mix'
import { fontFamily } from '../../theme/typi'

interface Props {
  primary?: boolean
  secondary?: boolean
  variant?: 'primary' | 'secondary'
}

export const Button = styled.button<Props>`
  display: inline-block;

  font-weight: bold;
  font-family: ${fontFamily.roboto};

  border-radius: 99999px;
  border: 2px solid ${color.turquoise};
  text-transform: uppercase;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
  }

  ${p =>
    (p.primary || p.variant === 'primary') &&
    css`
      padding: ${rem(sizes.size4)} ${rem(sizes.size7)};

      ${font.large};
      background-color: ${color.turquoise};
      color: ${color.white};

      &:hover {
        background-color: ${mix(0.2, color.black, color.turquoise)};
        border-color: ${mix(0.2, color.black, color.turquoise)};
      }
    `}

  ${p =>
    (p.secondary || p.variant === 'secondary') &&
    css`
      background-color: transparent;
      padding: ${rem(sizes.size4)} ${rem(sizes.size7)};

      ${font.base};
      color: ${color.turquoise};

      &:hover {
        background-color: ${color.turquoise};
        color: ${color.white};
      }
    `}
`
